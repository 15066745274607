import React from 'react'
import './Preloader.css';
import Gif from './../../assets/line.gif'
import logo from './../../assets/black.png'
const Preloader = () => {
    return (
        <div className='pbes__preloader-head'>
            <div className='pbes__preloader'>
            <div className='pbes__container'>
                <img src={logo} />
                    <img className='gif' src={Gif}  />
                <p>Today’s Resources For A Brighter Tomorrow</p>
            </div>
        </div>
            </div>

    )
}

export default Preloader