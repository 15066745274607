import React from 'react'
import './header.css'
import { FaCartArrowDown, FaCalculator } from "react-icons/fa";
import Solar from './../../assets/solar.png'
const header = () => {
  return (
    <div className='pbes__header '>
      <div className='pbes__header-items '>
      <div className='pbes__header_image'>
          <img src={Solar} width={800}/>
        </div>
        <div className='pbes__header-items_headings section__padding'>
          <h2>CHOOSE INNOVATIONS,</h2>
          <h1>Choose POWE<sp>R</sp> BRIDG<sp>E</sp>!</h1>
          <p>Select solar panels and make your contribution to ecology and life on the Earth!
            <br></br>This solution will positively affect your life!
          </p>
          <div className='pbes__header-items_btn'>
          <button className='Cart' type='button'><FaCartArrowDown />{'   '} Order Us</button>
          <button className='Calc' type='button'><FaCalculator /> Calculator</button>
        </div>
        </div>
      </div>
      <div className='container'>
       
      </div>

    </div>
  )
}

export default header