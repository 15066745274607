import React from 'react'
import './contact.css'
import { location, phone, email, time, WA, Insta, FB, YT, Link, TT } from './index'
const Contact = () => {
  return (
    <div className='pbes_contact' id='Contact'>
      <div className='pbes_contact_content section__padding'>
        <div className='pbes_contact_content_items'>
          <p>Our Contacts</p>
          <h3>Contact<br></br>Information</h3>
          <div className='pbes_contact_content_items_info'>
            <div className='info'>
              <img src={location} />
              <p>P-Block Johar Town Lahore, Punjab
                Pakistan</p>
            </div>
            <div className='info'>
              <img src={time} />
              <p>Monday-Saturday 09:00 AM - 05:00 PM
                Sunday Closed</p>
            </div>
            <div className='info'>
              <img src={email} />
              <p>Email: pbes786@gmail.com </p>
            </div>
            <div className='info'>
              <img src={phone} />
              <p>Tel: 0300-3018182</p>
            </div>

            <div className='social-icons'>
            <img src={WA} />
            <img src={Insta} />
            <img src={FB} />
            <img src={YT} />
            <img src={Link} />
            <img src={TT} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact