import React from 'react'
import './advantages.css'
import {Calc,chart,policy,hands,customer,partner1,premier,sky,beacon,ebr,zero} from './index'
const Advantages = () => {
    return (
        <div className='pbes_advant section__padding' id='About'>
            <div className='pbes_advant-headings'>
                <h5>ADVANTAGES</h5>
                <h2>You Get From Ordering at Power Bridge</h2>
            </div>
            <div className='pbes_advant_items'>
                <div className='pbes_advant_items-content'>
                    <div className='pbes_container'>
                        <div className='images'><img src={Calc} />
                        </div>
                        <div className='pbes_container-headings'><h5>CALCULATE YOUR SAVINGS</h5>
                            <p>With our savings calculator, you can make a right
                                decision on installation of solar panels
                                manufactured by Power Bridge</p>
                        </div>
                    </div>
                    <div className='pbes_container'>
                        <div className='images'><img src={chart} />
                        </div>
                        <div className='pbes_container-headings'><h5>INSTALLATION: HOW IT WORKS?</h5>
                            <p>Learn more about all aspects of the solar panel
                                installation process from our experts and get
                                answers to questions you have.</p>
                        </div>
                    </div>
                    <div className='pbes_container'>
                        <div className='images'><img src={policy} />
                        </div>
                        <div className='pbes_container-headings'><h5>OUR WARRANTY POLICY</h5>
                            <p>Our panels and other renewable energy products
                                come with a warranty that protects you against any
                                possible failures of the product.</p>
                        </div>
                    </div>
                    <div className='pbes_container'>
                        <div className='images'><img src={hands} />
                        </div>
                        <div className='pbes_container-headings'><h5>ADDITIONAL POWER FOR YOUR NEEDS</h5>
                            <p>Solar panels are perfect if you are looking for a
                                reliable source of additional power and energy for
                                your home or office.</p>
                        </div>
                    </div>
                    <div className='pbes_container'>
                        <div className='images'><img src={customer} />
                        </div>
                        <div className='pbes_container-headings'><h5>24/7 CUSTOMER SUPPORT</h5>
                            <p>Our company offers free 24/7 support to all our
                                clients who have any questions or issues with the
                                products and services of Power Bridge</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pbes_partner'>
                <div className='pbes_partner_content'>
                    <h3>Joint Vanture Prtners</h3>
                <div className='pbes_partner_content_items'>
                        <marquee behavior="scroll" direction="left">
                            <img src={partner1} />
                            <img src={premier} />
                            <img src={sky} />
                            <img src={beacon} />
                            <img src={ebr} />
                            <img src={zero} />
                        </marquee>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Advantages