import React, { useState } from 'react'
import './navbar.css'
import { RiCloseFill, RiMenu3Fill } from 'react-icons/ri';
import { logo } from './import';

const Menu = () =>
(
  <>
    <nav>
      <ul>
      </ul>
      <ul>
        <li><a href="#Home">Home</a></li>
        <li><a href="#Services">Services</a></li>
        <li><a href="#Projects">Projects</a></li>
        <li><a href="#About">About</a></li>
        <li><a href="#Contact">Contact</a></li>
      </ul>
    </nav>

  </>
)
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className='pbes__navbar-up' id='Home'>
      <div className='pbes__navbar-home section__padding'>
        <div className='pbes__navbar-home_logo'>
          <a href='#Home'><img src={logo} alt='Logo' /></a>
        </div>
        <div className='pbes__navbar-home_items'>
          <Menu />
        </div>
        <div className='pbes__nav-menu'>
          {
            toggleMenu
              ? <RiCloseFill color="#fff" size={27} onClick={() => setToggleMenu(false)} />
              : <RiMenu3Fill color="#fff" size={27} onClick={() => setToggleMenu(true)} />
          }
          {
            toggleMenu && (
              <div className='pbes__navbar-menu_container scale-up-center'>
                <div className='pbes__navbar-menu_container-links'>
                  <Menu />

                </div>
              </div>

            )
          }
        </div>
      </div>
    </div>
  )
}

export default Navbar 