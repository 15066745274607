import React, { useState, useEffect } from 'react'
import './App.css';
import '@picocss/pico'
import Navbar_up from './components/navbar-up/Navbar_up';
import Preloader from './containers/Preloader/Preloader';
import Navbar from './components/navbar/navbar';
import Header from './containers/header/header';
import Services from './containers/services/Services';
import Projects from './containers/Projects/Projects';
import Advantages from './containers/Advantages/Advantages';
import Contact from './containers/Contact/Contact';
import Footer from './components/Footer/Footer';

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>

          <div className='Head'>
          
          </div>
          <div className='Nav'>
            <Navbar_up />
            <Navbar />
            <Header />
          </div>
          <div>
            <Services />
            <Advantages />
            <Contact />
            <Footer />
          </div>
        </>
      )}
    </>
  );
}

export default App;
