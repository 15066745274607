import React from 'react'
import './footer.css'
import logo from '../../assets/logo.png'
const Footer = () => {
    return (
        <div className='pbes_footer'>
            <div className='pbes_footer section__padding'>
                <div className='pbes_footer_items'>
                    <img src={logo} />
                    <div className='right'>
                        <p>Designed By: <sp>Muhammad Awais</sp></p>
                        <a href='#Home'><button className='btn' type='button'>^</button></a>
                        </div>
                </div>
                <p className='copy'>© 2023 Power Bridge. All rights reserved.</p>
            </div>
        </div>
    )
}

export default Footer