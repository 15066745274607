import React from 'react'
import './services.css'
import {Install,Base,Cleaning,Meter,Inverter} from './import'
const Services = () => {
  return (
    <div className='pbes__service section__padding' id='Services'>
      <div className='pbes__service-heading'>
        <div className='pbes__service-heading_topic'>
          <h5>WE GOT COMPLETE PACKAGE FOR YOU</h5>
          <h1>Some Of Our Services</h1>
        </div>
        <div className='pbes__service-items'>
          <div className='pbes__service-items_groupA'>
            <div className='pbes__service-first'>
              <img src={Install} />
              <h4>Installation</h4>
              <p>By choosing to install solar panels with us, you are investing in a sustainable energy source that will benefit  you and the environment for years to come.</p>
            </div>
            <div className='pbes__service-first'>
            <img src={Base} />
            <h4>Base Structure</h4>
              <p>We don't just install the solar panels, we also provide the strong foundation for your precious investment.</p>
            </div>
            <div className='pbes__service-first'>
            <img src={Cleaning} />
              <h4>Cleaning Services</h4>
              <p>In addition to our solar panel installation services, we also provide comprehensive clean services</p>
            </div>
            <div className='pbes__service-first'>
          <img src={Meter} />
              <h4>Green Energy Meter</h4>
              <p>These meters are designed to provide you with real-time information on your energy consumption, allowing you to monitor and manage your energy usage more effectively.</p>
            </div>
            <div className='pbes__service-first'>
            <img src={Inverter} />
              <h4>Off Grid System</h4>
              <p>From solar panel installations and green energy meters to inverter and battery systems, we have the expertise and experience to provide you with the right solution for your specific energy needs</p>
            </div>
          </div>
          <div className='pbes__service-items_groupB'>
          
           
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services