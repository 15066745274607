import React from 'react'
import './Navbar_up.css'
import { location, phone, time, WA, Insta, FB, YT, Link, TT } from './index'

const Navbar_up = () => {
  return (
    <section>
      <div className='pbes__navbar_up'>
        <div className='pbes__navbar_up-contianer'>
          <div className='pbes__navbar_up-container_items-left'>
            <img src={location} />
            <p>P-Block Johar Town Lahore Punjab</p>
            <img src={phone} />
            <p>0300-3018182</p>
            <img src={time} />
            <p>Mon-Fri : 08:00 AM-05:00 PM </p>
          </div>
          <div className='pbes__navbar_up-container_items-right'>
            <img src={WA} />
            <img src={Insta} />
            <img src={FB} />
            <img src={YT} />
            <img src={Link} />
            <img src={TT} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Navbar_up